import { useEffect, useState } from 'react';
import { Container, Navbar, Nav, Form, Button, Col, Row, Card, ButtonGroup, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DataTable from 'react-data-table-component';
import Switch from "react-switch";
import md5 from "js-md5";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { formatToCPFOrCNPJ } from 'brazilian-values';


export default function App() {

  const alerta = withReactContent(Swal)

  const azulPadrao = '#215497'

  const [loading, setLoading] = useState(false)


  const [modalIncluirLista, setModalIncluirLista] = useState(false)
  const [modalIncluirUsuario, setModalIncluirUsuario] = useState(false)
  const [modalTesteLista, setModalTesteLista] = useState(false)
  const [edicaoLista, setEdicaoLista] = useState(false)
  const [edicaoUsuario, setEdicaoUsuario] = useState(false)


  const [checkPermProd, setCheckPermProd] = useState(false)
  const [checkPermForn, setCheckPermForn] = useState(false)


  const [empresaAtual, setEmpresaAtual] = useState({})
  const [configuracao, setConfiguracao] = useState({})
  const [lista, setLista] = useState({})
  const [usuario, setUsuario] = useState({})

  const [produtos, setProdutos] = useState([])
  const [listaSimulada, setListaSimulada] = useState([])
  const [fornecedores, setFornecedores] = useState([])
  const [listas, setListas] = useState([])
  const [usuarios, setUsuarios] = useState([])

  const conexaoOutBox = {
    "endereco": process.env.REACT_APP_ENDERECO_CONEXAO
  }


  const [localizado, setLocalizado] = useState(false)
  const [pagina, setPagina] = useState('login')

  useEffect(() => {
    if (pagina === 'produtos') {
      getProdutos()
    }
    if (pagina === 'fornecedores') {
      getFornecedores()
    }
    if (pagina === 'listas') {
      getListas()
    }
    if (pagina === 'usuarios') {
      getUsuarios()
    }
    if (pagina === 'configuracoes') {
      getConfiguracoes()
    }
  }, [pagina])

  function FormataPrimeiraMaiuscula(texto) {

    const arr = texto.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }
    const str2 = arr.join(" ");
    console.log(str2);
    return str2
  }

  function localizaEmpresa(identificacao) {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/empresas/listar'

    const body = {
      "identificacao": identificacao.toUpperCase()
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      if (requisicao.status === 200) {
        let resposta = JSON.parse(this.response)
        setEmpresaAtual({
          ...empresaAtual,
          razao: resposta.empr_razaosocial,
          usuarios: resposta.usuarios,
          listas: resposta.listas,
          fornecedores: resposta.fornecedores,
          produtos: resposta.produtos,
          empresa: resposta.empr_id
        })
        setLocalizado(true)
        setPagina('dashboard')
      } else {
        alerta.fire('Atenção', 'Empresa Não Localizada!')
      }

    }

  }

  function getProdutos() {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/produtos/listar'

    const body = {
      "empresa": empresaAtual.empresa,
      "lista": lista.id
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      setListaSimulada([])
      setProdutos([])
      if (requisicao.status === 200) {
        if (lista.id) {
          setListaSimulada(JSON.parse(this.response))
        } else {
          setProdutos(JSON.parse(this.response))
        }
      }
    }

  }

  function getFornecedores() {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/fornecedores/listar'

    const body = {
      "empresa": empresaAtual.empresa
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      setFornecedores([])
      if (requisicao.status === 200) {
        setFornecedores(JSON.parse(this.response))
      }

    }

  }

  function getListas() {

    setLoading(true)
    setListas([])

    const url = conexaoOutBox.endereco + '/listas/listar'

    const body = {
      "empresa": empresaAtual.empresa
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      
      if (requisicao.status === 200) {
        setListas(JSON.parse(this.response))
      }
    }

  }

  function insertLista() {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/listas/incluir'

    const body = {
      "empresa": empresaAtual.empresa,
      "descricao": lista.descricao,
      "comandosql": lista.comandosql,
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      if (requisicao.status === 201) {
        setModalIncluirLista(false)
        setLista({})
        getListas()
      } else {
        alerta.fire('Atenção', 'Falha ao Incluir a Lista!')
      }

    }

  }

  function insertUsuario() {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/usuarios/incluir'

    const body = {
      "empresa": empresaAtual.empresa,
      "nome": usuario.nome,
      "email": usuario.email,
      "senha": md5(usuario.senha).toUpperCase(),
      "usuario_erp": usuario.usuario_erp,
      "perm_prod": usuario.perm_prod === true ? 'S' : 'N',
      "perm_forn": usuario.perm_forn === true ? 'S' : 'N'
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      if (requisicao.status === 201) {
        setModalIncluirUsuario(false)
        setUsuario({})
        getUsuarios()
      } else {
        alerta.fire('Atenção', 'Falha ao Incluir o Usuário!')
      }

    }

  }

  function updateLista() {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/listas/atualizar'

    const body = {
      "id": lista.id,
      "descricao": lista.descricao,
      "comandosql": lista.comandosql,
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      if (requisicao.status === 200) {
        alerta.fire('Atenção', 'Lista Atualizada com Sucesso', 'success')
        getListas()
      } else {
        alerta.fire('Atenção', 'Falha ao Atualizar a Lista!')
      }

    }

  }

  function updateUsuario() {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/usuarios/atualizar'

    const body = {
      "id": usuario.id,
      "nome": usuario.nome,
      "email": usuario.email,
      "usuario_erp": usuario.usuario_erp,
      "perm_prod": usuario.perm_prod === true ? 'S' : 'N',
      "perm_forn": usuario.perm_forn === true ? 'S' : 'N'
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      if (requisicao.status === 200) {
        setModalIncluirUsuario(false)
        getUsuarios()
      } else {
        alerta.fire('Atenção', 'Falha ao Atualizar o Usuário!')
      }

    }

  }

  function deleteLista() {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/listas/remover'

    const body = {
      "id": lista.id
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      if (requisicao.status === 200) {
        setModalIncluirLista(false)
        getListas()
      } else {
        alerta.fire('Atenção', 'Falha ao Remover a Lista!')
      }

    }

  }

  function deleteUsuario() {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/usuarios/remover'

    const body = {
      "id": usuario.id
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      if (requisicao.status === 200) {
        setModalIncluirUsuario(false)
        setUsuario({})
        getUsuarios()
      } else {
        alerta.fire('Atenção', 'Falha ao Remover Usuário!')
      }

    }

  }

  function getUsuarios() {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/usuarios/listar'

    const body = {
      "empresa": empresaAtual.empresa
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      if (requisicao.status === 200) {
        setUsuarios(JSON.parse(this.response))
      } else {
        setUsuario([])
        // alerta.fire('Atenção', 'Nenhum Fornecedor Localizado!')
      }

    }

  }

  function getConfiguracoes() {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/configuracoes/listar'

    const body = {
      "empresa": empresaAtual.empresa
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      if (requisicao.status === 200) {
        setConfiguracao(JSON.parse(this.response))
      } else {
        setConfiguracao({})
      }

    }

  }

  function updateConfiguracoes() {

    setLoading(true)

    const url = conexaoOutBox.endereco + '/configuracoes/atualizar'

    const body = {
      "empresa": empresaAtual.empresa,
      "filtro_produtos": configuracao.conf_filtro_produtos,
      "filtro_fornecedores": configuracao.conf_filtro_fornecedores,
      "documento": configuracao.conf_dcto_pcp,
      "especie": configuracao.conf_espe_pcp
    }

    console.log('Endereço Configurado: ' + url)

    let requisicao = new XMLHttpRequest()

    requisicao.timeout = 40000
    requisicao.open("POST", url, true)
    requisicao.setRequestHeader("Content-Type", "application/json")
    requisicao.setRequestHeader("Authorization", conexaoOutBox.autorizacao)
    requisicao.send(JSON.stringify(body))

    requisicao.ontimeout = function () {
      setLoading(false)
      alert('Falha na Conexão com a API RP Info')
      console.log('Deu TimeOut')
    }

    requisicao.onerror = function () {
      console.log('Deu Erro: ' + this.responseText)
    }

    requisicao.onload = function () {
      setLoading(false)
      if (requisicao.status === 200) {
        alerta.fire('Atenção', 'Configurações Atualizadas com Sucesso', 'success')
        getConfiguracoes()
      } else {
        alerta.fire('Atenção', 'Falha ao Gravar Configurações!')
      }

    }

  }

  const paginationComponentOptions = {
    rowsPerPageText: 'Registros por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const colunasProdutos = [
    {
      name: 'Código',
      selector: row => row.prod_codigo,
      sortable: true,
      maxWidth: '5px',
    },
    {
      name: 'Descrição',
      selector: row => row.prod_descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Marca',
      selector: row => row.prod_marca,
      sortable: true,
      maxWidth: '250px',
    },
    {
      name: 'EAN',
      selector: row => row.prod_codbarras,
      sortable: true,
      maxWidth: '150px',
    }
  ];

  const colunasFornecedores = [
    {
      name: 'Código',
      selector: row => row.forn_codigo,
      sortable: true,
      maxWidth: '5px'
    },
    {
      name: 'Nome',
      selector: row => row.forn_nome,
      sortable: true,
      wrap: true
    },
    {
      name: 'Razão Social',
      selector: row => row.forn_razaosocial,
      sortable: true
    },
    {
      name: 'CNPJ / CPF',
      selector: row => row.forn_cnpj,
      format: row => formatToCPFOrCNPJ(row.forn_cnpj),
      sortable: true,
      maxWidth: '250px'
    }
  ];

  const colunasListas = [
    {
      name: 'Descrição',
      selector: row => row.list_descricao,
      sortable: true,
      wrap: true
    },
    {
      name: 'Filtro SQL',
      selector: row => row.list_comandosql,
      sortable: true,
      wrap: true
    }
  ];

  const colunasUsuarios = [
    {
      name: 'Nome',
      selector: row => row.usua_nome,
      format: row => FormataPrimeiraMaiuscula(row.usua_nome),
      sortable: true,
      wrap: true
    },
    {
      name: 'E-mail',
      selector: row => row.usua_email,
      sortable: true
    },
    {
      name: 'Usuário ERP',
      selector: row => row.usua_user_erp,
      sortable: true,
      maxWidth: '160px'
    },
    {
      name: 'Cadastro Produtos',
      selector: row => row.usua_perm_cad_prod,
      sortable: true,
      maxWidth: '200px'
    },
    {
      name: 'Cadastro Fornecedores',
      selector: row => row.usua_perm_cad_forn,
      sortable: true,
      maxWidth: '200px'
    }
  ];

  const tableDataProdutos = {
    columns: colunasProdutos,
    data: produtos,
    filterPlaceholder: 'Filtrar',
    exportHeaders: true,
    print: false,
    export: false
  };

  const tableDataFornecedores = {
    columns: colunasFornecedores,
    data: fornecedores,
    filterPlaceholder: 'Filtrar',
    exportHeaders: true,
    print: false,
    export: false
  };

  return (
    <div>
      <Navbar style={{ backgroundColor: azulPadrao }} variant='dark'>
        <Container>
          <Navbar.Brand onClick={() => { setPagina('dashboard') }} style={{ cursor: 'pointer' }}>
            <strong>Compras Mobile</strong>
          </Navbar.Brand>

          {localizado &&
            <Nav className='me-right '>
              <Nav.Link href='#' className='active botao-menu' onClick={() => { setPagina('usuarios') }}>
                Usuários
              </Nav.Link>
              <Nav.Link href='#' className='active botao-menu' onClick={() => { setPagina('listas') }}>
                Listas
              </Nav.Link>
              <Nav.Link href='#' className='active botao-menu' onClick={() => { setPagina('fornecedores') }}>
                Fornecedores
              </Nav.Link>
              <Nav.Link href='#' className='active botao-menu' onClick={() => { setPagina('produtos') }}>
                Produtos
              </Nav.Link>
              <Nav.Link href='#' className='active botao-menu' onClick={() => { setPagina('configuracoes') }}>
                Configurações
              </Nav.Link>
              <Nav.Link href='#' className='active botao-menu' onClick={() => {
                setEmpresaAtual({})
                setLocalizado(false)
                setPagina('login')
              }}>
                Sair
              </Nav.Link>
            </Nav>
          }
        </Container>

      </Navbar>

      <Modal
        show={loading}
        backdrop="static"
        keyboard={false}
        centered
        size='sm'
      >
        <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src='https://bitrix.rpinfo.com.br/rpdesk/production/app/resources/img/loading-rp.gif' width={120} height={120} />
        </Modal.Body>

      </Modal>

      {!localizado && pagina === 'login' && //Container Login
        <Container
          style={{ paddingTop: 240 }}
        >
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col lg={4}>



              <Form autoComplete='off'>
                <Form.Group className='mb-3' controlId='formBasicText'>
                  <Form.Label>
                    Identificador do Cliente
                  </Form.Label>
                  <Form.Control type='text' style={{ textTransform: 'uppercase' }} placeholder='Identificador Único de Cada Cliente' value={empresaAtual.identificacao} onChange={(e) => { setEmpresaAtual({ ...empresaAtual, identificacao: e.target.value }) }} />
                </Form.Group>
                <Button
                  style={{ color: 'white', backgroundColor: azulPadrao, border: 'none', width: '100%', marginTop: 20 }}
                  type='button'
                  onClick={() => {


                    if (empresaAtual.identificacao) {
                      localizaEmpresa(empresaAtual.identificacao)
                    } else {
                      alerta.fire('Atenção', 'Informe o Identificador da Empresa', 'warning')
                    }


                    // setLocalizado(true)


                    // alerta.fire({
                    //   title: 'Atenção',
                    //   text: 'Identificador Não Localizado',
                    //   icon: 'info',
                    //   showDenyButton: true,
                    //   denyButtonText: 'Cancelar',
                    //   showConfirmButton: true,
                    //   confirmButtonText: 'Cadastrar',
                    //   confirmButtonColor: azulPadrao

                    // })
                    //   .then((result) => {
                    //     if (result.isConfirmed) {
                    //       alerta.fire('Cadastrar', 'Você clicou em Cadastrar', 'success')
                    //       setPagina('dashboard')
                    //     } else if (result.isDenied) {
                    //       alerta.fire('Cancelar', 'Você clicou em Cancelar', 'info')
                    //       setPagina('dashboard')
                    //     }
                    //   })



                  }}
                >
                  Localizar Cadastro
                </Button>
              </Form>

            </Col>

          </Row>

        </Container>
      }

      {localizado && pagina === 'dashboard' && //container dashboard 
        <Container style={{ marginTop: 40 }} >
          <Row>
            <Col>
              <strong>{empresaAtual?.identificacao.toUpperCase()}</strong>
              <p>{empresaAtual?.razao}</p>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <strong>Última Integração
              </strong>
              <p>14/12/2022 - 12:45:22</p>
            </Col>
          </Row>
          <Row style={{ marginTop: 40 }}>
            <Col>
              <Card
                onClick={() => { setPagina('usuarios') }}
                key={'card1'}
                text={'white'}
                style={{ width: '18rem', cursor: 'pointer' }}
                className="mb-2 card-azul"
              >
                <Card.Body>
                  <Card.Title> <strong>Usuários</strong> </Card.Title>
                  <Card.Text style={{ textAlign: 'right', fontSize: 48 }}>
                    {empresaAtual?.usuarios}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                onClick={() => { setPagina('listas') }}
                key={'card1'}
                text={'white'}
                style={{ width: '18rem', cursor: 'pointer' }}
                className="mb-2 card-azul"
              >
                <Card.Body>
                  <Card.Title> <strong>Listas</strong> </Card.Title>
                  <Card.Text style={{ textAlign: 'right', fontSize: 48 }}>
                    {empresaAtual?.listas}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                onClick={() => { setPagina('fornecedores') }}
                key={'card1'}
                text={'white'}
                style={{ width: '18rem', cursor: 'pointer' }}
                className="mb-2 card-azul"
              >
                <Card.Body>
                  <Card.Title> <strong>Fornecedores</strong> </Card.Title>
                  <Card.Text style={{ textAlign: 'right', fontSize: 48 }}>
                    {empresaAtual?.fornecedores}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                onClick={() => { setPagina('produtos') }}
                key={'card1'}
                text={'white'}
                style={{ width: '18rem', cursor: 'pointer' }}
                className="mb-2 card-azul"
              >
                <Card.Body>
                  <Card.Title> <strong>Produtos</strong> </Card.Title>
                  <Card.Text style={{ textAlign: 'right', fontSize: 48 }}>
                    {empresaAtual?.produtos}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      }

      {localizado && pagina === 'listas' && //container listas 
        <Container style={{ marginTop: 40 }} >
          <Row>
            <Col>
              <strong>Listas</strong>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <ButtonGroup size='sm'>
                <Button style={{ color: 'white', backgroundColor: azulPadrao, border: 'none' }} onClick={() => {
                  setEdicaoLista(false)
                  setLista({})
                  setModalIncluirLista(true)
                }}>Incluir</Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <DataTable
                columns={colunasListas}
                data={listas}
                onRowClicked={(row, index) => {
                  setEdicaoLista(true)
                  setLista({ ...lista, descricao: row.list_descricao, comandosql: row.list_comandosql, id: row.list_id })
                  setModalIncluirLista(true)
                }}
                striped={true}
                highlightOnHover={true}
                responsive={true}
                dense
                pointerOnHover={true}
                noDataComponent={"Nenhum Registro Encontrado =/"}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
              />
            </Col>
          </Row>
          <Modal
            show={modalIncluirLista}
            onHide={() => {
              setModalIncluirLista(false)
              setLista({})
            }}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              {edicaoLista &&
                <Modal.Title>Editando Lista</Modal.Title>
              }
              {!edicaoLista &&
                <Modal.Title>Nova Lista</Modal.Title>
              }
            </Modal.Header>
            <Modal.Body>

              <Form>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='formBasicText'>
                      <Form.Label>
                        Descrição
                      </Form.Label>
                      <Form.Control type='text' size='sm' style={{ textTransform: 'capitalize' }} autoFocus value={lista.descricao} onChange={(e) => { setLista({ ...lista, descricao: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='formBasicText'>
                      <Form.Label>
                        Filtro SQL
                      </Form.Label>
                      <Form.Control as='textarea' rows={3} size='sm' value={lista.comandosql} onChange={(e) => { setLista({ ...lista, comandosql: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>

            </Modal.Body>
            <Modal.Footer>

              {edicaoLista &&
                <Button
                  style={{ color: 'white', backgroundColor: 'green', border: 'none', marginTop: 20 }}
                  onClick={() => {
                    setModalIncluirLista(false)
                    setModalTesteLista(true)
                    getProdutos()
                  }}
                >
                  Testar Lista
                </Button>
              }

              {edicaoLista &&
                <Button
                  style={{ color: 'white', backgroundColor: 'brown', border: 'none', marginTop: 20 }}
                  onClick={() => {
                    deleteLista()
                  }}
                >
                  Remover Lista
                </Button>
              }

              {!edicaoLista &&
                <Button
                  style={{ color: 'white', backgroundColor: azulPadrao, border: 'none', marginTop: 20 }}
                  onClick={() => {
                    insertLista()
                  }}
                >
                  Salvar Lista
                </Button>
              }
              {edicaoLista &&
                <Button
                  style={{ color: 'white', backgroundColor: azulPadrao, border: 'none', marginTop: 20 }}
                  onClick={() => {
                    updateLista()
                  }}
                >
                  Atualizar Lista
                </Button>
              }


            </Modal.Footer>

          </Modal>

          <Modal
            show={modalTesteLista}
            onHide={() => {
              setModalIncluirLista(true)
              setModalTesteLista(false)
            }}
            backdrop="static"
            keyboard={false}
            centered
            size='lg'
          >
            <Modal.Header closeButton>
              <Modal.Title>Simulação de Lista</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <DataTable
                    columns={colunasProdutos}
                    data={listaSimulada}
                    // onRowClicked={(row, index) => {
                    //     GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                    //     CarregaDetalheColetas(row.departamento, row.vencimento)
                    // }}
                    striped={true}
                    highlightOnHover={true}
                    responsive={true}
                    dense
                    // pointerOnHover={true}
                    noDataComponent={"Nenhum Registro Encontrado =/"}
                    pagination={true}
                    paginationComponentOptions={paginationComponentOptions}
                  />
                </Col>
              </Row>

            </Modal.Body>
            <Modal.Footer>

              <Button
                style={{ color: 'white', backgroundColor: azulPadrao, border: 'none', marginTop: 20 }}
                onClick={() => {
                  setModalIncluirLista(true)
                  setModalTesteLista(false)
                }}
              >
                Voltar
              </Button>



            </Modal.Footer>

          </Modal>
        </Container>
      }

      {localizado && pagina === 'usuarios' && //container usuarios 
        <Container style={{ marginTop: 40 }} >
          <Row>
            <Col>
              <strong>Usuários</strong>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <ButtonGroup size='sm'>
                <Button style={{ color: 'white', backgroundColor: azulPadrao, border: 'none' }} onClick={() => {
                  setEdicaoUsuario(false)
                  setUsuario({})
                  setModalIncluirUsuario(true)
                }}>Incluir</Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <DataTable
                columns={colunasUsuarios}
                data={usuarios}
                onRowClicked={(row, index) => {
                  setEdicaoUsuario(true)
                  setUsuario({ ...usuario, nome: row.usua_nome, email: row.usua_email, usuario_erp: row.usua_user_erp, id: row.usua_id, senha: row.usua_senha, perm_prod: row.usua_perm_cad_prod === 'S' ? true : false, perm_forn: row.usua_perm_cad_forn === 'S' ? true : false })
                  setModalIncluirUsuario(true)
                }}
                striped={true}
                highlightOnHover={true}
                responsive={true}
                dense
                pointerOnHover={true}
                noDataComponent={"Nenhum Registro Encontrado =/"}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
              />
            </Col>
          </Row>
          <Modal
            show={modalIncluirUsuario}
            onHide={() => {
              setModalIncluirUsuario(false)
              setUsuario({})
            }}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              {edicaoUsuario &&
                <Modal.Title>Editando Usuário</Modal.Title>
              }
              {!edicaoUsuario &&
                <Modal.Title>Novo Usuário</Modal.Title>
              }
            </Modal.Header>
            <Modal.Body>

              <Form>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='formBasicText'>
                      <Form.Label>
                        Nome do Usuário
                      </Form.Label>
                      <Form.Control type='text' size='sm' style={{ textTransform: 'capitalize' }} autoFocus value={usuario.nome} onChange={(e) => { setUsuario({ ...usuario, nome: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='formBasicText'>
                      <Form.Label>
                        Endereço de E-mail
                      </Form.Label>
                      <Form.Control type='email' size='sm' style={{ textTransform: 'lowercase' }} value={usuario.email} onChange={(e) => { setUsuario({ ...usuario, email: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8}>
                    <Form.Group className='mb-3' controlId='formBasicText'>
                      <Form.Label>
                        Senha de Acesso
                      </Form.Label>
                      <Form.Control type='password' disabled={edicaoUsuario} size='sm' style={{ textTransform: 'lowercase' }} value={usuario.senha} onChange={(e) => { setUsuario({ ...usuario, senha: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className='mb-3' controlId='formBasicText'>
                      <Form.Label>
                        Usuário ERP
                      </Form.Label>
                      <Form.Control type='number' size='sm' style={{ textTransform: 'lowercase' }} value={usuario.usuario_erp} onChange={(e) => { setUsuario({ ...usuario, usuario_erp: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col style={{ display: 'flex', alignItems: 'center' }}>
                    <Switch
                      checked={usuario.perm_prod}
                      onChange={(e) => {
                        setUsuario({ ...usuario, perm_prod: e })
                        setCheckPermProd(!setCheckPermProd)
                      }}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      className="react-switch"
                      id="disabled-switch"
                    />
                    <span style={{ marginLeft: 15 }}>Permitir Pré-Cadastro de Produtos</span>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col style={{ display: 'flex', alignItems: 'center' }}>
                    <Switch
                      checked={usuario.perm_forn}
                      onChange={(e) => {
                        setUsuario({ ...usuario, perm_forn: e })
                        setCheckPermForn(!setCheckPermForn)
                      }}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      className="react-switch"
                      id="disabled-switch"
                    />
                    <span style={{ marginLeft: 15 }}>Permitir Pré-Cadastro de Fornecedores</span>
                  </Col>
                </Row>

              </Form>

            </Modal.Body>
            <Modal.Footer>

              {edicaoUsuario &&
                <Button
                  style={{ color: 'white', backgroundColor: 'brown', border: 'none', marginTop: 20 }}
                  onClick={() => {
                    deleteUsuario()
                  }}
                >
                  Remover Usuário
                </Button>
              }

              {!edicaoUsuario &&
                <Button
                  style={{ color: 'white', backgroundColor: azulPadrao, border: 'none', marginTop: 20 }}
                  onClick={() => {
                    insertUsuario()
                  }}
                >
                  Salvar Usuário
                </Button>
              }
              {edicaoUsuario &&
                <Button
                  style={{ color: 'white', backgroundColor: azulPadrao, border: 'none', marginTop: 20 }}
                  onClick={() => {
                    updateUsuario()
                  }}
                >
                  Atualizar Usuário
                </Button>
              }


            </Modal.Footer>

          </Modal>
        </Container>
      }
      {localizado && pagina === 'produtos' && //container produtos 
        <Container style={{ marginTop: 40 }} >
          <Row>
            <Col>
              <strong>Produtos</strong>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col>
              <DataTableExtensions {...tableDataProdutos}>
                <DataTable
                  columns={colunasProdutos}
                  data={produtos}
                  // onRowClicked={(row, index) => {
                  //     GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                  //     CarregaDetalheColetas(row.departamento, row.vencimento)
                  // }}
                  striped={true}
                  highlightOnHover={true}
                  responsive={true}
                  dense
                  // pointerOnHover={true}
                  noDataComponent={"Nenhum Registro Encontrado =/"}
                  pagination={true}
                  paginationComponentOptions={paginationComponentOptions}
                />
              </DataTableExtensions>
            </Col>
          </Row>
        </Container>
      }
      {localizado && pagina === 'fornecedores' && //container fornecedores 
        <Container style={{ marginTop: 40 }} >
          <Row>
            <Col>
              <strong>Fornecedores</strong>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col>
              <DataTableExtensions {...tableDataFornecedores}>
                <DataTable
                  columns={colunasFornecedores}
                  data={fornecedores}
                  // onRowClicked={(row, index) => {
                  //     GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                  //     CarregaDetalheColetas(row.departamento, row.vencimento)
                  // }}
                  striped={true}
                  highlightOnHover={true}
                  responsive={true}
                  dense
                  // pointerOnHover={true}
                  noDataComponent={"Nenhum Registro Encontrado =/"}
                  pagination={true}
                  paginationComponentOptions={paginationComponentOptions}
                />
              </DataTableExtensions>
            </Col>
          </Row>
        </Container>
      }
      {localizado && pagina === 'configuracoes' && //container configuracoes 
        <Container style={{ marginTop: 40 }} >
          <Row>
            <Col>
              <strong>Configurações</strong>
            </Col>
          </Row>
          <Row style={{ marginTop: 40 }}>
            <Col>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='formBasicText'>
                      <Form.Label>
                        Filtro SQL Padrão para Produtos
                      </Form.Label>
                      <Form.Control as='textarea' rows={3} size='sm' value={configuracao.conf_filtro_produtos} onChange={(e) => { setConfiguracao({ ...configuracao, conf_filtro_produtos: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='formBasicText'>
                      <Form.Label>
                        Filtro SQL Padrão para Fornecedores
                      </Form.Label>
                      <Form.Control as='textarea' rows={3} size='sm' value={configuracao.conf_filtro_fornecedores} onChange={(e) => { setConfiguracao({ ...configuracao, conf_filtro_fornecedores: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='formBasicText'>
                      <Form.Label>
                        Documento PCP
                      </Form.Label>
                      <Form.Control type='text' size='sm' style={{ textTransform: 'uppercase' }} value={configuracao.conf_dcto_pcp} onChange={(e) => { setConfiguracao({ ...configuracao, conf_dcto_pcp: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3' controlId='formBasicText'>
                      <Form.Label>
                        Espécie PCP
                      </Form.Label>
                      <Form.Control type='text' size='sm' style={{ textTransform: 'uppercase' }} value={configuracao.conf_espe_pcp} onChange={(e) => { setConfiguracao({ ...configuracao, conf_espe_pcp: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      style={{ color: 'white', backgroundColor: azulPadrao, border: 'none', width: '100%', marginTop: 20 }}
                      type='button'
                      onClick={() => {
                        updateConfiguracoes()
                      }}

                    >
                      Salvar Configurações
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      }


    </div >
  );
}
